
footer {
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.25rem;
        height: 3.25rem;
        border: solid 4px;
        box-shadow: black 6px 8px 0px 0px;
        transition: filter 0.5s ease-out;

        img {
            width: 1.8rem;
        }
    }

    a:hover {
        filter: brightness(0.9);
    }
}
