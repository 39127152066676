@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Jersey 15';
  src: local('Jersey 15'), url('./assets/fonts/Jersey15Custom.ttf') format('truetype');
  size-adjust: 70%;
}

@font-face {
  font-family: 'Jersey 25';
  src: local('Jersey 55'), url('./assets/fonts/Jersey25Custom.ttf') format('truetype');
  size-adjust: 70%;
}

body {
  margin: 0;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  /* shape-rendering: geometricPrecision; */
  
  background-color: theme('colors.owaldron-bg');
  font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: large;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

@media(max-width:1024px) {
  .center {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.jersey-15 {
  font-family: "Jersey 15", serif;
  font-weight: 400;
  font-style: normal;
}

.jersey-25 {
  font-family: "Jersey 25", serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: "Jersey 25", serif;
  font-weight: 400;
  font-style: normal;
}


/* Use some styles from https://www.30secondsofcode.org/css/s/hover-underline-animation/ */

.hover-underline {
  display: inline-block;
  position: relative;
}

.hover-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #fff;
  transform-origin: bottom center;
  transition: transform 0.5s ease-out;
}

.hover-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

